<template>
  <el-container class="home-ct">
    <el-header>
      <div>
        <img src=".././assets/wlogo.png" alt="" width="160" height="40" />
        <span></span>
      </div>
      <el-dropdown class="user-name" trigger="click">
        <span class="el-dropdown-link">
          {{ username }}
          <i class="el-icon-caret-bottom"></i>
        </span>
        <!-- <template> -->
        <el-dropdown-menu>
          <el-dropdown-item @click.native="edit_MM">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
        <!-- </template> -->
      </el-dropdown>
    </el-header>
    <el-container>
      <el-aside :width="isclose ? '64px' : '200px'">
        <div class="toggle-button" @click="togglecomp">
          <i
            :class="isclose ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            style="font-size: 16px"
          ></i>
        </div>
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409eff"
          :collapse="isclose"
          :collapse-transition="false"
          router
          :default-active="acatvePath"
          :default-openeds="openeds"
        >
          <el-submenu
            :index="item.id + ''"
            v-for="item in menlist"
            :key="item.id"
          >
            <template slot="title">
              <i :class="iconobj[item.id]" style="color: #e8edee ;"></i>
              <span>{{ item.label }}</span>
            </template>
            <!-- 二级菜单 -->
            <div v-for="(sitem, index) in item.children" :key="index">
              <!-- 判断二级菜单（没有三级菜单）-->
              <el-menu-item
                :index="'/' + sitem.name + ''"
                v-if="!sitem.children"
                @click="saveNavstate('/' + sitem.name)"
              >
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{ sitem.label }}</span>
                </template>
              </el-menu-item>

              <!-- 判断二级菜单（有三级菜单）-->
              <el-submenu :index="'/' + sitem.name + ''" v-if="sitem.children">
                <template slot="title">
                  <i :class="iconobj[sitem.id]" style="color: #8f959e;"></i>
                  <span>{{ sitem.label }}</span>
                </template>
                <el-menu-item
                  :index="'/' + sitem.name + ''"
                  v-for="sitem in sitem.children"
                  :key="sitem.id"
                  @click="saveNavstate('/' + sitem.name)"
                >
                  <template slot="title">
                    <i class="el-icon-menu"></i>
                    <span>{{ sitem.label }}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </div>
            <!-- 
            <div v-if="item.children[0].children">
              <el-submenu
                :index="'/' + sitem.name + ''"
                v-for="sitem in item.children"
                :key="sitem.id"
                @click="saveNavstate('/' + sitem.name)"
              >
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{ sitem.label }}</span>
                </template>
                <el-menu-item
                  :index="'/' + sitem.name + ''"
                  v-for="sitem in sitem.children"
                  :key="sitem.id"
                  @click="saveNavstate('/' + sitem.name)"
                >
                  <template slot="title">
                    <i class="el-icon-menu"></i>
                    <span>{{ sitem.label }}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </div> -->
            <!-- <div v-else>
              <el-menu-item
                :index="'/' + sitem.name + ''"
                v-for="sitem in item.children"
                :key="sitem.id"
                @click="saveNavstate('/' + sitem.name)"
              >
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{ sitem.label }}</span>
                </template>
              </el-menu-item>
            </div> -->
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main :style="isclose ? 'left:64px;' : 'left:200px;'">
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-dialog title="修改密码" :visible.sync="VMM" width="35%">
      <el-form
        :model="formM"
        ref="formM"
        :rules="rules"
        label-width="120px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="输入密码" prop="old">
          <el-input v-model="formM.old" show-password></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="password" required>
          <el-input v-model="formM.password" show-password></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="VMM = false">取消</el-button>
        <el-button type="primary" @click="edit_QR">确认</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
// import { mapState } from 'vuex'
import rules from '../utils/rules.js'
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formM.old) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      username: '',
      menlist: [],
      openeds: [],
      iconobj: {
        1: 'iconfont icon-user',
        2: 'iconfont icon-tijikongjian',
        30: 'iconfont icon-wenzhang-copy',
        35: 'iconfont icon-report-fill',
        37: 'iconfont icon-baobiao',
        56: 'iconfont icon-users',
        40: 'iconfont icon-3702mima',
        55: 'iconfont icon-guanggaoguanli',
        60: 'iconfont icon-gongju',
        125: 'el-icon-video-camera-solid show',
        133: 'el-icon-s-opportunity show',
        156: 'iconfont icon-qiyehao',
        160: 'iconfont icon-hezuoguanli',
        174: 'iconfont icon-dingdan',
        176: 'iconfont icon-neirongguanli',
        177: 'iconfont icon-yonghuguanli',
        178: 'iconfont icon-chanpinmokuai',
        179: 'iconfont icon-xitongguanliyuanguanli'
      },
      rules: {
        old: [
          rules.req('输入密码'),
          { min: 6, max: 15, message: '长度在 6 到 15个字符', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass2, trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15个字符', trigger: 'blur' }
        ]
      },
      formM: {
        old: '',
        password: ''
      },
      isclose: false,
      acatvePath: '',
      baseurl: this.$store.state.env.cos,
      VMM: false
    }
  },
  created() {
    this.getMgetMenulist()
    // this.acatvePath = sessionStorage.getItem('atp')
    this.acatvePath = location.hash.slice(1)
    // this.$router.push(this.acatvePath)
    this.getenv()
    // this.$store.commit('getconfig')
    this.increment()
    // console.log(this.baseurl);
    // console.log(process.env.VUE_APP_UPLOAD_FILE_URL);
    // console.log();
    // const KEY = 'OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK'
    // console.log(location.href);
  },
  methods: {
    increment() {
      //调用type，触发handler（state）
      if (!this.$store.state.config) {
        this.$store.commit('getConfiguration')
      }
    //   if(location.hash.includes('enterprise')){
    //       this.$store.commit('enterpriseDictionary')
    //   }
    },

    logout() {
      // console.log(1)

      this.$http.post('/admin/AuthMember/logOut').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          sessionStorage.removeItem('token')
          localStorage.removeItem('menu')
        } else {
          this.$message.error(res.message)
        }
      })
      this.$router.push('/login')
    },
    edit_MM() {
      this.VMM = true
    },
    edit_QR() {
      this.$refs.formM.validate(vaid => {
        if (vaid) {
          this.$http
            .post('/admin/AuthMember/editPassword', {
              ...this.formM
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.VMM = false
                this.$refs.formM.resetFields()
              } else {
                this.$message.error(res.message)
              }
            })
        }
      })
    },
    getMgetMenulist() {
      // const { data: res } = await this.$http.get('menus')
      // this.menlist = res.data
      this.username = this.$cookies.get('username')
      this.menlist = JSON.parse(sessionStorage.getItem('menu'))
      if (!this.$store.state.name) {
        this.menlist = JSON.parse(localStorage.getItem('menu'))
      }
      // console.log(sessionStorage.getItem('menu'));
      // this.menlist = JSON.parse(this.$cookies.get('menu'))
      // this.menlist.forEach((item) => {
      //   console.log(item.children)
      // })
      // console.log(this.menlist)
    },
    togglecomp() {
      this.isclose = !this.isclose
    },
    saveNavstate(atp) {
      // sessionStorage.setItem('atp', atp)

      // console.log(atp);
      // console.log(atp =='/activity_add');
      // if (JSON.parse(sessionStorage.getItem('flag'))) {
      //   console.log(1)
      // } else {
      //   location.reload()
      //   sessionStorage.setItem('flag', true)
      // }
      if (
        (location.href.includes('/activity_add') && atp == '/activity_add') ||
        (location.href.includes('/article_add') && atp == '/article_add') ||
        (location.href.includes('/report_add') && atp == '/report_add') ||
        (location.href.includes('/system_admin_add') &&
          atp == '/system_admin_add')||(location.href.includes('/enterprise_add') &&
          atp == '/enterprise_add')
      ) {
        // location.reload()
        this.bus.$emit('new')
      }
      // if (location.href.includes(atp)) {
      //   location.reload()
      // }
      // console.log(Location.href)
      this.acatvePath = atp
      if (this.acatvePath == '/activity_add') {
        sessionStorage.setItem('action', 'activity')
      }

      // this.bus.$emit('saveNavstate',atp)
    },
    getenv() {
      this.$http.get('/admin/SysConfig/getEnvConfig').then(({ data: res }) => {
        // console.log(res);
        if (res.data) sessionStorage.setItem('env', JSON.stringify(res.data))
      })
    }
  },
  mounted() {
    // this.$cookies.set('test', 'ded', '0')

    setTimeout(() => {
      if (this.acatvePath && !location.href.includes(this.acatvePath))
        this.$router.push(this.acatvePath)
    }, 200)
  }
  // computed: {
  //   ...mapState(['env'])
  // }
}
</script>

<style lang="less" scoped>
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  position: relative;
  width: 100%;
  height: 60px;

  img {
    vertical-align: middle;
  }
  span {
    margin-left: 15px;
  }
}
.el-aside {
  background-color: #333744;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 60px;
  bottom: 0;
  overflow-y: scroll;
}
.home-ct {
  height: 100%;
}
.iconfont {
  margin-right: 10px;
}
.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.sd {
  background: cornsilk;
}
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 250px;
  line-height: 70px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
.show {
  width: 17px;
  margin-right: 10px;
  // transform: translateX(-5px);
}
</style>
